import React from "react";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

import ResearchAndDevelopmentDocuments from "../../components/research-and-development/ResearchAndDevelopmentDocuments";

export default function ResearchAndDevelopmentCamlsPage({data}) {
    return (
        <article>
            <Helmet>
                <title>CAMLs</title>
            </Helmet>
            <h2>CAMLs</h2>
            <p>Стадия: Подготовка регистрационных документов</p>
            <ResearchAndDevelopmentDocuments>
                <ResearchAndDevelopmentDocuments.Document
                    title="инструкция"
                    url={data["instructionsFile"]["publicURL"]} />
                <ResearchAndDevelopmentDocuments.Document
                    title="патент"
                    url={data["patentFile"]["publicURL"]} />
                <ResearchAndDevelopmentDocuments.Document
                    title="презентация"
                    url={data["presentationFile"]["publicURL"]} />
            </ResearchAndDevelopmentDocuments>
            <figure>
                <StaticImage
                    src="img/camls-1.jpg"
                    alt="Прототип устройства для поиска и фиксации ЦОК и CAML."
                    layout="constrained"
                    width={310} />
                <figcaption>Прототип устройства для поиска и фиксации ЦОК и CAML.</figcaption>
            </figure>
            <p>Гигантские клетки миелоидного происхождения (CD14+/CD11c+), имеют увеличенные ядра и размеры, являются CD45+ и проявляют цитоплазматическое окрашивание цитокератином 8, 18 и 19 и молекулой адгезии эпителиальных клеток (EpCAM), что характерно для эпителиальных клеток.</p>
            <ul>
                <li>Являются универсальным онкомаркером рецидива онкозаболевания;</li>
                <li>Индикатором адекватности выполненной операции по удаление опухоли;</li>
                <li>Позволяет дифференцировать злокачественные и доброкачественные процессы;</li>
                <li>Позволяет оценивать эффективность химотерапии;</li>
                <li>Служит носителем генетической информации опухоли, способствуя повышению точности жидкой биопсии.</li>
            </ul>
            <h2>Визуализация на микроскопе Zeiss Primo Star (Carl Zeiss, Германия)</h2>
            <p>Увеличение х400</p>
            <figure>
                <StaticImage
                    src="img/camls-2.png"
                    alt="CAML. Пациентка 54 лет. Рак яичников  III стадия."
                    layout="constrained"
                    width={620} />
                <figcaption>CAML. Пациентка 54 лет. Рак яичников  III стадия.</figcaption>
            </figure>
            <figure>
                <StaticImage
                    src="img/camls-3.jpg"
                    alt="Циркулирующая опухолевая клетка, позитивная мембранная реакция на EpCAM. Пациентка 67 лет. Рак молочной железы II стадия."
                    layout="constrained"
                    width={620} />
                <figcaption>Циркулирующая опухолевая клетка, позитивная мембранная реакция на EpCAM. Пациентка 67 лет. Рак молочной железы II стадия.</figcaption>
            </figure>
            <figure>
                <StaticImage
                    src="img/camls-4.png"
                    alt="CAML. Позитивная реакция на EpCAM. Пациентка 54 лет. Рак яичников  III стадия."
                    layout="constrained"
                    width={620} />
                <figcaption>CAML. Позитивная реакция на EpCAM. Пациентка 54 лет. Рак яичников  III стадия.</figcaption>
            </figure>
        </article>
    );
}

export const pageQuery = graphql`
    query {
        instructionsFile: file(relativePath: {eq: "instruktsiia-caml.pdf"}) {
            publicURL
        }
        patentFile: file(relativePath: {eq: "patent-tsok-enerdzh.pdf"}) {
            publicURL
        }
        presentationFile: file(relativePath: {eq: "bolshaiia-prezentatsiia.pdf"}) {
            publicURL
        }
    }  
`;
